<template>
    <l-map
        :zoom="zoom"
        :center="center"
        :zoomAnimation="true"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated"
    >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :draggable="true" :lat-lng="center" ref="marker" @dragend="onDragEnd"></l-marker>
        <l-control position="bottomleft">
            <b-button variant="light" size="sm" @click="refreshLocation" style="padding: 7px">
                <feather-icon size="20" icon="SendIcon"/>
            </b-button>
        </l-control>
    </l-map>
</template>

<script>
import {LMap, LTileLayer, LControl, LMarker} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import {Icon} from "leaflet";

export default {
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControl
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 17,
            bounds: {
                _northEast: {
                    lat: 41.360060975177106,
                    lng: 69.37866210937501
                },
                _southWest: {
                    lat: 41.26645287188313,
                    lng: 69.05181884765626
                }
            },
            latitude: 41.349937,
            longitude: 69.19946,
            center: [41.309534, 69.254036],
            marker: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.marker = this.$refs.marker
        })
    },
    methods: {
        onDragEnd(event) {
            if (this.marker) {
                this.latitude = this.marker.latLng[0]
                this.longitude = this.marker.latLng[1]
            }
        },
        refreshLocation() {
            this.getGeolocation()
            this.zoom = 16
        },
        getGeolocation() {
            if (!navigator.geolocation) {
                return;
            }
            // Request the user's coordinates
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude
                this.longitude = position.coords.longitude
                this.center = [this.latitude, this.longitude]
            });
        },
        zoomUpdated(zoom) {
            this.zoom = zoom;
        },
        centerUpdated(center) {
            if (center)
                this.center = [center.lat, center.lng];
        },
        boundsUpdated(bounds) {
            this.bounds = bounds;
        }
    },
    created() {
        delete Icon.Default.prototype._getIconUrl;
        Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });
    }
}
</script>

<style lang="scss">
.vue2leaflet-map {
    &.leaflet-container {
        height: 350px;
    }
}
</style>
