<template>
    <SetLocation/>
</template>

<script>
import SetLocation from '@/components/SetLocation'

export default {
    name: 'AllContractors',
    components: { SetLocation },
}
</script>

<style scoped>

</style>