<template>
    <b-card>
        <div class="d-flex mb-2">
            <div>
                <feather-icon
                    @click="$router.go(-1)"
                    size="20"
                    class="mr-1 cursor-pointer"
                    icon="ArrowLeftIcon"
                />
            </div>
            <h5 class="m-0 ml-2">{{ contractor && contractor.name }}</h5>
        </div>

        <h5 class="mb-2">
            <feather-icon class="cursor-pointer mr-1" style="margin-bottom: 10px" size="18" icon="MapPinIcon"/>
            <span>Выбранный адрес: </span>
            <b-spinner v-if="loading" small></b-spinner>
            <span class="text-info" v-else>{{ address ? address.display_name : 'не указано' }}</span>
        </h5>
        <b-card-body class="p-0">
            <MapComponent ref="MapComponent"/>
            <b-button variant="primary" class="mt-1 w-100" @click="onSaveLocation">Сохранить адрес</b-button>
            <b-button class="mt-1 w-100" @click="()=>{$refs.MapComponent.refreshLocation()}">
                <feather-icon class="cursor-pointer mr-1" size="16" icon="MapPinIcon"/>
                <span>Местоположение клиента</span>
            </b-button>
        </b-card-body>
    </b-card>
</template>

<script>
import ContractorService from '../services/contractor.service'
import AddressService from '../services/address.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {mapGetters, mapMutations} from 'vuex'
import MapComponent from "@/components/MapComponent";

export default {
    name: 'SetLocation',
    components: {MapComponent},
    computed: {
        ...mapGetters({contractors: 'getContractors'}),
        contractor() {
            return this.contractors.find(contractor => contractor.id === this.$route.params.id)
        },
    },
    data() {
        return {
            address: null,
            loading: false
        }
    },
    methods: {
        ...mapMutations(['pushUpdateContractor']),
        onSaveLocation() {
            let payload = {
                name: this.contractor.name,
                contacts: this.contractor.contacts,
                type: this.contractor.type,
                location: {
                    latitude: this.$refs.MapComponent.latitude,
                    longitude: this.$refs.MapComponent.longitude,
                },
            }
            ContractorService.updateContractor(this.contractor.id, payload).then(async res => {
                this.pushUpdateContractor(res.data);
                await this.getAddress()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Ваше адрес успешно указано',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Возникла ошибка',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
        },
        getAddress() {
            if (this.contractor && this.contractor.location) {
                this.loading = true
                let params = {
                    lat: this.contractor.location.latitude,
                    lon: this.contractor.location.longitude,
                }
                AddressService.getAddress(params)
                    .then(res => {
                        this.address = res.data
                        this.$refs.MapComponent.latitude = this.contractor.location.latitude
                        this.$refs.MapComponent.longitude = this.contractor.location.longitude
                        this.$refs.MapComponent.center = [this.contractor.location.latitude, this.contractor.location.longitude]
                    })
                    .catch(err => {
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    },
    mounted() {
        this.getAddress()
    }
}
</script>

<style scoped>

</style>