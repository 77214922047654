import axiosIns from "@/libs/axios";

class AddressService {
    getAddress(params) {
        return axiosIns.get(`/api/address/get`, {params: params})
    }

}

export default new AddressService()
